import Vue from 'vue';
import VueRouter from "vue-router";
import Meta from 'vue-meta';
import store from '../store';
import GeneralRoutes from './general';
import AdminRoutes from './admin';
import UserRoutes from './user';
import ClubRoutes from './club';


Vue.use(VueRouter)
Vue.use(Meta)

var allRoutes = []

allRoutes = allRoutes.concat(
    GeneralRoutes,
    AdminRoutes,
    UserRoutes,
    ClubRoutes

)


const routes = allRoutes;

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((to, from, next) => {
    function findrouteAuthLevel_in_UserAuthLevel(arr1, arr2) {
        return arr1.some(item => arr2.includes(item))
    }

    const routeAuthLevel = to.meta.authLevels;
    const userAuthLevel = store.getters['UserAuth/GET_AUTH_LEVEL']; // Auch für Club

    if (findrouteAuthLevel_in_UserAuthLevel(routeAuthLevel, userAuthLevel)) next();
    else next('/login')
});

export default router
