
//User Routen
const routes =  [
    {   path: '/user/home',
        name: '/user/home',
        component: () => import(/* webpackChunkName: "uwelcome" */ '../../../views/user/uwelcome'),
        meta: {requiresAuth: true, authLevels: [1]}
    },
    {   path: '/user/education',
        name: '/user/education',
        component: () => import(/* webpackChunkName: "education" */ '../../../views/user/education'),
        meta: {requiresAuth: true, authLevels: [1]}
    },
    {   path: '/user/myclub',
        name: '/user/myclub',
        component: () => import(/* webpackChunkName: "myclub" */ '../../../views/user/myclub'),
        meta: {requiresAuth: true, authLevels: [1]}
    },
    {   path: '/user/profil',
        name: '/user/profil',
        component: () => import(/* webpackChunkName: "profil" */ '../../../views/user/profil'),
        meta: {requiresAuth: true, authLevels: [1]}
    },
    {   path: '/user/recordtime',
        name: '/user/recordtime',
        component: () => import(/* webpackChunkName: "recordtime" */ '../../../views/user/recordtime'),
        meta: {requiresAuth: true, authLevels: [1]}
    },
    {   path: '/user/shootingbook',
        name: '/user/shootingbook',
        component: () => import(/* webpackChunkName: "shootingbook" */ '../../../views/user/shootingbook'),
        meta: {requiresAuth: true, authLevels: [1]}
    },
    {   path: '/user/weapon',
        name: '/user/weapon',
        component: () => import(/* webpackChunkName: "weapon" */ '../../../views/user/weapon'),
        meta: {requiresAuth: true, authLevels: [1]}
    },
    {   path: '/user/changePassword',
        name: '/user/changePassword',
        component: () => import(/* webpackChunkName: "changePassword" */ '../../../views/user/changePassword'),
        meta: {requiresAuth: true, authLevels: [1]}
    },
    {   path: '/user/analytics',
        name: '/user/analytics',
        component: () => import(/* webpackChunkName: "analytics" */ '../../../views/user/analytics'),
        meta: {requiresAuth: true, authLevels: [1]}
    },



];



export default routes
