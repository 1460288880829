import {API} from "../../../axios";
import swal from "sweetalert2";

const actions = {

     login({ dispatch, commit }, user){

        return new Promise((resolve, reject) => {
            commit('auth_request')
             API.post('user/login',user)
                .then(resp => {

                    const token = resp.data.access_token
                    const user = resp.data.user
                    const defClub = resp.data.defClub
                    const usermenu = resp.data.usermenu

                    localStorage.setItem('token', token)
                    API.defaults.headers.common['Authorization'] = `Bearer ${token}`
                    dispatch('UserApp/getuwelcomeText', null, { root: true })
                    dispatch('UserApp/getTextNewUser', null, { root: true })
                    commit('auth_success', { user, token, defClub, usermenu })
                    dispatch('UserApp/getDashboard', null, { root: true })
                    dispatch('UserApp/getEduData', null, { root: true })
                    dispatch('UserApp/getWeapons', null, { root: true })
                    dispatch('UserApp/getClubs', null, { root: true })
                    dispatch('UserApp/getMyShootingbook', null, { root: true })
                    dispatch('UserApp/getOpenShootingBookData', null, { root: true })
                    dispatch('UserApp/getShootingbookCountData', null, { root: true })
                    dispatch('UserApp/getAllDiszi', null, { root: true })
                    resolve(resp)
                })
                .catch(err => {
                    // console.log(err.response)
                    swal.fire('Anmeldung!', err.response.data,'warning');
                    commit('auth_error')
                    localStorage.removeItem('token')
                    reject(err)
                })
        })
    },

    logout({commit, state}){
        return new Promise((resolve, reject) => {
            API.post('user/logout',
                {  id: state.user.id
                })
                .then(() => {
                    localStorage.removeItem('token')
                    delete API.defaults.headers.common['Authorization']
                    commit('logout')
                    commit('UserApp/set_appDataClear', null, { root: true })
                    resolve()
                })
                .catch(err => {
                    // console.log(err)
                    commit('auth_error', err)
                    localStorage.removeItem('token')
                    reject(err)
                })

        })
    },

    async setUserProfilData({dispatch,rootState}, Data){
        API.defaults.headers.common['Authorization'] = `Bearer ${rootState.UserAuth.token}`
        await API.post('/user/profil/update', Data)
            .then(() => {
                dispatch('getUserProfilData');
                swal.fire('Profil Update', 'Du hast dein Profil erfolgreich aktualisiert','info');
            })
            .catch(error => {
                console.log(error);
                swal.fire('Profil Update', error.response.data,'warning');
            });

    },

    async setNewPassword({dispatch,rootState}, Data){
        API.defaults.headers.common['Authorization'] = `Bearer ${rootState.UserAuth.token}`
        await API.post('/user/profil/changePassword', Data)
            .then(() => {
                dispatch('getUserProfilData');
                swal.fire('Passwort geändert', 'Dein Passwort wurde geändert','info');
            })
            .catch(error => {
                console.log(error);
                swal.fire('Passwort ändern', error.response.data,'warning');

            });

    },

    getUserProfilData({commit, dispatch, rootState}){
        API.defaults.headers.common['Authorization'] = `Bearer ${rootState.UserAuth.token}`
        API.get('/user/profil')
            .then((resp) => {
                const user = resp.data.user
                const defClub = resp.data.defClub
                const usermenu = resp.data.usermenu
                commit('SET_PROFIL_DATA', { user, defClub, usermenu })
                dispatch('UserApp/getOpenShootingBookData', null, { root: true })
            })
            .catch(error => {
                console.log(error);
            });
    },

    async registerUser({ commit }, Data){
        await API.post('/user/register', Data)
            .then((resp) => {
                const message = resp.data.message
                swal.fire('Profil angelegt', message, 'success');
                commit('REGISTER_USER')
            })
            .catch(error => {
                console.log(error);
                swal.fire('Fehler Profil anlegen', 'Es ist etwas schiefgelaufen beim anlegen deines Profils', 'warning');
            });

    },

};

export default actions;
