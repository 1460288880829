

//Generelle Routen
const routes =  [
    {   path: '/',
        name: '',
        component: () => import(/* webpackChunkName: "welcome" */ '../../../views/component/welcome'),
        meta: {authLevels: [0]}
    },

    {   path: '/Datenschutz',
        name: 'Datenschutz',
        component: () => import(/* webpackChunkName: "Datenschutz" */ '../../../views/component/Datenschutz'),
        meta: {authLevels: [0]}
    },

    {   path: '/about',
        name: 'about',
        component: () => import(/* webpackChunkName: "about" */ '../../../views/component/about'),
        meta: {authLevels: [0]}
    },

    {   path: '/contactus',
        name: 'contactus',
        component: () => import(/* webpackChunkName: "about" */ '../../../views/component/ContactUs'),
        meta: {authLevels: [1,2]}
    },

    {   path: '/register',
        name: 'register',
        component: () => import(/* webpackChunkName: "register" */ '../../../views/component/register'),
        meta: {authLevels: [0]}
    },

    {   path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "login" */ '../../../views/component/login'),
        meta: {authLevels: [0]}
    },

    {   path: '/loginQR',
        name: 'loginQR',
        component: () => import(/* webpackChunkName: "login" */ '../../../views/component/loginwithQRCode'),
        meta: {authLevels: [0]}
    },

    // {   path: '/club/register',
    //     name: 'clubregister',
    //     component: () => import(/* webpackChunkName: "register" */ '../../../views/component/register'),
    //     meta: {authLevels: [0]}
    // },
    //
    // {   path: '/club/login',
    //     name: 'clublogin',
    //     component: () => import(/* webpackChunkName: "login" */ '../../../views/component/login'),
    //     meta: {authLevels: [0]}
    // },

];

export default routes
