const getters = {

    isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
    // isAdmin_old: state => !!state.user.isAdmin,
    user: state => state.user,
    isAdmin: (state) => {
        if (state.user === null) {
            return false;
        }
        return  !!state.user.isAdmin;
    },
    isUserName: (state) => {
        if (state.user === null) {
            return '';
        }
        const UserName = state.user.vorname + ' ' + state.user.name;
        return UserName;
    },
    isVereinsAdmin: (state) => {
        if (state.user === null) {
            return false;
        }
        return !!state.user.isVereinAdmin;
    },
    GET_USERMENU: (state) => {

        return state.usermenu;
    },

    GET_AUTH_LEVEL: (state, getters, rootState, rootGetters) => {
        // 0: 'Unknown',
        // 1: 'User',
        // 2: 'Club',
        // 3: 'ClubAdmin',
        // 4: 'Admin',
        // 5: 'Club Terminal'

        var Auth_Level = [0];

        if(getters.isLoggedIn){
            Auth_Level.push(1)
        }
        if(rootGetters['ClubAuth/isClubLoggedIn']){
            Auth_Level.push(2);
        }
        if(getters.isAdmin){
            Auth_Level.push(4);
        }
        if(getters.isVereinsAdmin){
            Auth_Level.push(3);
        }
        if(rootGetters['ClubAuth/can_RFID']){
            Auth_Level.push(5);
        }
        return Auth_Level;
    },




};

export default getters;
