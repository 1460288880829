<template>
    <v-card>

        <v-navigation-drawer
            v-model="comp_NavigationDrawer"
            temporary
            app
        >
            <v-list-item class="px-2">
                <v-list-item-avatar>
                  <v-avatar color="green">
                    <v-icon dark>
                      mdi-account-multiple
                    </v-icon>
                  </v-avatar>
                </v-list-item-avatar>


                <v-list-item-content>

                    <v-list-item-title>{{ club_Name }}</v-list-item-title>

                </v-list-item-content>


<!--                <v-btn-->
<!--                    icon-->
<!--                    @click.stop="mini = !mini"-->
<!--                >-->
<!--                    <v-icon>mdi-chevron-left</v-icon>-->
<!--                </v-btn>-->
            </v-list-item>

            <v-divider></v-divider>


            <!--            Einträge Dynamisch    -->
            <v-list
                class="menu-list"
                shaped
            >
                <v-list-group
                    v-for="item in comp_Menu_entrys"
                    :key="item.title"
                    v-model="item.expand"
                >
                    <template v-slot:activator>


                        <v-list-item-avatar
                            class="mr-4">
                            <v-img
                                :lazy-src="item.icon"
                                max-height="30"
                                max-width="30"
                                :src="item.icon"
                            ></v-img>
                        </v-list-item-avatar>

                        <v-list-item-content>
                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                        </v-list-item-content>

                    </template>


                    <v-list-item
                        v-for="entry in item.entry"
                        :to="entry.route"
                        :key="entry.title"
                        class="pl-10"
                        link

                    >
                        <v-list-item-avatar>
                            <v-img
                                :lazy-src="entry.icon"
                                max-height="20"
                                max-width="20"
                                :src="entry.icon"
                            ></v-img>

                        </v-list-item-avatar>
                        <v-list-item-title v-text="entry.title"></v-list-item-title>


                      <v-divider v-show="entry.divider"></v-divider>
                    </v-list-item>


                </v-list-group>
            </v-list>

        </v-navigation-drawer>
    </v-card>

</template>

<script>
export default {
    name: "sidebar",

    data() {
        return {
            drawer: true,
            mini: true,

        }
    },
    computed: {
        club_Name() {
            return this.$store.state.ClubAuth.club.name;
        },
      comp_NavigationDrawer: {
        get() {
          return this.$store.getters['GeneralApp/GET_NavbarDrawer'];
        },
        set(val) {
          this.$store.state.GeneralApp.NavigationDrawer = val;
        },
      },
      comp_Menu_entrys() {
        return this.$store.getters['ClubAuth/GET_CLUBMENU'];
      },
    },

}
</script>

<style scoped>

</style>
