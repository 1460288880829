import axios from 'axios';

const baseURL = process.env.VUE_APP_API_URL + process.env.VUE_APP_API_VERSION;
const axios_timeout = process.env.VUE_APP_AXIOS_TIMEOUT

export const API = axios.create({

    baseURL: baseURL,
    timeout: axios_timeout,
    headers: {

        'Content-Type': 'application/json;charset=UTF-8',
    },

})
