<template>
    <v-card>

        <v-navigation-drawer
            v-model="comp_NavigationDrawer"
            temporary
            app
        >
            <v-list-item class="px-2">
                <v-list-item-avatar>
                  <v-avatar color="green">
                    <v-icon dark>
                      mdi-account-circle
                    </v-icon>
                  </v-avatar>
                </v-list-item-avatar>


                <v-list-item-content>

                    <v-list-item-title>{{ user_Name }}</v-list-item-title>
                    <v-list-item-subtitle>{{ defClub_Name }}</v-list-item-subtitle>

                </v-list-item-content>


<!--                <v-btn-->
<!--                    icon-->
<!--                    @click.stop="mini = !mini"-->
<!--                >-->
<!--                    <v-icon>mdi-chevron-left</v-icon>-->
<!--                </v-btn>-->
            </v-list-item>

            <v-divider></v-divider>


            <!--            Einträge Dynamisch    -->
            <v-list class="menu-list">
                <v-list-group
                    v-for="item in Get_UserMenu"
                    :key="item.title"
                    v-model="item.expand"
                >
                    <template v-slot:activator>


                        <v-list-item-avatar
                            class="mr-4">
                            <v-img
                                :lazy-src="item.icon"
                                max-height="30"
                                max-width="30"
                                :src="item.icon"
                            ></v-img>
                        </v-list-item-avatar>

                        <v-list-item-content>
                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                        </v-list-item-content>

                    </template>


                    <v-list-item
                        v-for="entry in item.entry"
                        :to="entry.route"
                        :key="entry.title"
                        class="pl-10"
                        link

                    >
                        <v-list-item-avatar>
                            <v-img
                                :lazy-src="entry.icon"
                                max-height="20"
                                max-width="20"
                                :src="entry.icon"
                            ></v-img>

                        </v-list-item-avatar>
                        <v-list-item-title v-text="entry.title"></v-list-item-title>

                    </v-list-item>

                </v-list-group>

            </v-list>

            <v-divider></v-divider>

        </v-navigation-drawer>
    </v-card>

</template>

<script>
export default {
    name: "sidebar",

    data() {
        return {
            drawer: false,
            mini: false,

        }
    },
    computed: {
        user_Name() {
            return this.$store.state.UserAuth.user.vorname + ' ' + this.$store.state.UserAuth.user.name;
        },
        defClub_Name() {
            return this.$store.state.UserAuth.defClub;
        },
      Get_UserMenu() {
          return this.$store.getters['UserAuth/GET_USERMENU'];
      },
      comp_NavigationDrawer: {
          get() {
            return this.$store.getters['GeneralApp/GET_NavbarDrawer'];
          },
          set(val) {
            this.$store.state.GeneralApp.NavigationDrawer = val;
          },

      },
    },

}
</script>

<style scoped>

</style>
