import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'
import '@fortawesome/fontawesome-free/css/all.css' // Ensure you are using css-loader
import colors from 'vuetify/lib/util/colors'
import DatetimePicker from 'vuetify-datetime-picker'

Vue.use(Vuetify);
Vue.use(DatetimePicker)

const opts = {
    icons: {
        iconfont: 'mdi' || 'fa', // default - only for display purposes 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
    },
    theme:{
        primary: colors.red.darken1, // #E53935
        secondary: colors.red.lighten4, // #FFCDD2
        accent: colors.indigo.base, // #3F51B5
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        danger: '#D32F2F',
    },


}

export default new Vuetify(opts);
