const mutations = {

    auth_success(state, { user, token, defClub, usermenu } ){
        state.status = 'success',
        state.token = token,
        state.user = user,
        state.defClub = defClub,
        state.usermenu = usermenu
    },

    auth_error(state){
        state.status = 'error'
    },

    auth_request(state){
        state.status = 'loading'
    },
    SET_PROFIL_DATA(state, { user, defClub, usermenu } ){
             state.user = user,
            state.defClub = defClub,
            state.usermenu = usermenu
    },
    REGISTER_USER(){

    },

    logout(state){
        state.status = '',
        state.token = null,
        state.user = null,
        state.defClub = null,
        state.usermenu = null

    },
};

export default mutations;
