const getters = {
    weapon_Disciplines: (state) => (waffen_id) => {
        if (waffen_id > 0) {
            const Diszi = state.Weapons.find(wd => wd.id === waffen_id)
            return Diszi.disciplines;
        }else{
            return [];
        }
    },
    has_Education_Supervision: (state) => {
      const Education = state.Education.myEdu.find(es => es.can_Supervision === 1)
        if (typeof Education === 'undefined'){
            return false
        }else{
            return true
        }
    },
};

export default getters;
