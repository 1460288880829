const mutations = {

    get_UWelcomeText(state,data) {
        this.state.UserApp.TextUwelcome = data
    },
    get_TextNewUser(state,data) {
        this.state.UserApp.TextNewUser = data
    },
    get_TextUeberlassung(state,data) {
        this.state.UserApp.TextUeberlassung = data
    },
    get_Education(state, {allEdu, myEdu}){
        this.state.UserApp.Education.allEdu = allEdu,
        this.state.UserApp.Education.myEdu = myEdu
    },
    get_Weapons(state,data) {
        this.state.UserApp.Weapons = data
    },
    get_Diszi_Weapons(state,data) {
        this.state.UserApp.DisziWeapon = data
    },
    get_Diszi_Single_Weapons(state,data) {
        this.state.UserApp.DisziSingleWeapon = data
    },
    get_Diszi_All(state,data) {
        this.state.UserApp.Discipline = data
    },
    get_MyClubs(state,data) {
        this.state.UserApp.Clubs = data
    },
    get_MyShootingbook(state,data) {
        this.state.UserApp.MyShootingbook = data
    },
    get_OpenShootingBookEntry(state,data) {
        this.state.UserApp.OpenShootingBookEntry = data
    },
    get_OpenShootingBookData(state,data) {
        this.state.UserApp.OpenShootingBookData = data
    },
    GET_SHOOTINGRANGEFREEPLACES(state,data) {
        this.state.UserApp.ShootingRangeFreeplaces = data
    },
    GET_DASHBOARD(state,data) {
       state.Dashboard.WeaponsCount = data.WCount,
       state.Dashboard.WeaponsTransferCount = data.WTCount, // Die habe ich überlassen bekommen
       state.Dashboard.WeaponsMyTransferCount = data.WTMyCount, // Die habe ich überlassen
       state.Dashboard.SBCount = data.SbCount,
       state.Dashboard.SBOpenCount = data.SbOpenCount,
       state.Dashboard.SBLastEntry = data.SbLast,
       state.Dashboard.WeeklyShootingCount = data.WeeklyShootingCount
    },
    GET_CHARTSDATA(state,data) {
        state.Charts = data
    },
    GET_SHOOTINGBOOK_COUNT(state,data) {
        state.shootingbook_Count = data
    },
    CLEAR_SHOOTINGBOOK_SIGNATURE(state){
        state.Shootingbook_Entry_Signature = [];
    },
    GET_SHOOTINGBOOK_SIGNATURE(state,data){
        state.Shootingbook_Entry_Signature = data;
    },
    set_appDataClear(state){
        state.TextUwelcome = "",
        state.TextNewUser = "",
        state.TextUeberlassung = "",
        state.Education.myEdu = [],
        state.Education.allEdu = [],
        state.Weapons = [],
        state.Clubs = [],
        state.MyShootingbook = [],
        state.OpenShootingBookEntry = [],
        state.OpenShootingBookData = [],
        state.Discipline = [],
        state.DisziSingleWeapon = [],
        state.DisziWeapon = [],
        state.Dashboard=  {
            WeaponsCount: '',
            WeaponsTransferCount: '',
            WeaponsMyTransferCount: '',
            SBCount: '',
            SBOpenCount: '',
            SBLastEntry: [],
            WeeklyShootingCount: []
        },
        state.Charts = null,
        state.shootingbook_Count = null,
        state.Shootingbook_Entry_Signature = []
    },
};

export default mutations;
