const mutations = {

    auth_success(state, { club, token, clubmenu, club_qrcode } ){
        state.status = 'success',
        state.Clubtoken = token,
        state.club = club,
        state.clubmenu = clubmenu
        if(club_qrcode){
            state.club_qrcode = club_qrcode.QrCode
        }
    },

    auth_error(state){
        state.status = 'error'
    },

    auth_request(state){
        state.status = 'loading'
    },

    SET_PROFIL_DATA(state, { club } ){
        state.club = club
    },
    REGISTER_CLUB(){

    },
    SET_CLUB_QRCODE(state, data){
        state.club_qrcode = data;
    },

    logout(state){
        state.status = '',
        state.Clubtoken = '',
        state.club = null,
        state.clubmenu = null,
        state.club_qrcode = null
    },


};

export default mutations;
