import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate';
import SecureLS from 'secure-ls';
import UserApp from './user/app/';
import UserAuth from './user/auth/';
import ClubApp from './club/app/';
import ClubAuth from './club/auth/';
import GeneralApp from './app/';
import AdminApp from './admin/app';

const ls = new SecureLS({ isCompression: false });

Vue.use(Vuex)

export default new Vuex.Store ({

    plugins: [
        createPersistedState(
             {
                 storage: {
                     getItem: (key) => ls.get(key),
                     setItem: (key, value) => ls.set(key, value),
                     removeItem: (key) => ls.remove(key),
                 },
                // storage: window.localStorage,
                // storage: window.sessionStorage,
            },
        ),
    ],
    modules: {
        UserApp,
        UserAuth,
        ClubApp,
        ClubAuth,
        GeneralApp,
        AdminApp,

    },
});
