<template>

  <v-app>

    <v-app-bar
        style="background-color: #32Cd32"
        height="100px"
        app
    >
      <v-app-bar-nav-icon
          v-show="comp_ShowNavigation"
          v-if="isLoggedIn || isClubLoggedIn"
          @click.stop="comp_NavigationDrawer = !comp_NavigationDrawer">
      </v-app-bar-nav-icon>

      <router-link to="/">
        <img
            src="/Bilder/book.png"
            width="50"
            height="50"
            class="rounded-circle mr-5">
      </router-link>
      <v-toolbar-title
          class="text-lowercase white--text">
        schützenbuch.de
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <div
          v-if="isLoggedIn && comp_ShowNavigation"
          class="white--text mr-4"
      >
        <img
            src="/Bilder/home.png"
            class="mr-2 rounded-circle"
            width="20"
            height="20"

        >
        {{ this.$store.state.UserAuth.defClub }}

      </div>


      <v-btn
          v-if="isLoggedIn || isClubLoggedIn"
          @click="logout()"
          class="ma-2"
          color="success">
        <v-icon class="ma-2">mdi-export</v-icon>
        Logout
      </v-btn>

      <v-btn
          v-if="!isLoggedIn && !isClubLoggedIn"
          to="/login"
          class="ma-2"
          color="success">
        <v-icon class="ma-2">mdi-export</v-icon>
        Anmelden
      </v-btn>

      <v-btn
          v-show="comp_ShowNavigation"
          v-if="!isLoggedIn && !isClubLoggedIn"
          to="/register"
          class="ma-2"
          color="white success--text">
        <v-icon class="ma-2">mdi-cloud-upload</v-icon>
        Registrieren
      </v-btn>


    </v-app-bar>


    <sidebar
        v-show="comp_ShowNavigation"
        v-if="isLoggedIn"></sidebar>
    <clubsidebar
        v-show="comp_ShowNavigation"
        v-if="isClubLoggedIn"></clubsidebar>


    <v-main>

      <router-view></router-view>

    </v-main>

    <v-footer
        v-show="comp_ShowNavigation"
    >
      <cookie-law theme="dark-lime">
        <div slot="message">
          Diese Seite verwendet Cookies. Genaueres findest du unter
          <router-link to="Datenschutz">Datenschutz</router-link>
        </div>
      </cookie-law>
      <v-row
          justify="center"
          style="background-color: #32Cd32"
          no-gutters
      >
        <v-btn
            v-for="link in links"
            :key="link.name"
            color="white"
            text
            rounded
            class="my-2"
            :to="link.route"
        >
          {{ link.name }}
        </v-btn>
        <v-btn
            v-if="isLoggedIn || isClubLoggedIn"
            color="white"
            text
            rounded
            to="/contactus"
            class="my-2">
          Feedback
        </v-btn>
        <v-col
            class="py-4 text-center white--text"
            style="background-color: #32Cd32"
            cols="12"
        >
          {{ new Date().getFullYear() }} — schuetzenbuch.de
        </v-col>
      </v-row>


    </v-footer>


  </v-app>

</template>

<script>
// import welcome from "./component/welcome";
import sidebar from "./component/sidebar"
import clubsidebar from './club/component/sidebar.vue';
import CookieLaw from 'vue-cookie-law';

export default {
  name: "index",
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'schuetzenbuch.de',
    // all titles will be injected into this template
    titleTemplate: '%s'
  },

  components: {
    // welcome,
    sidebar,
    clubsidebar,
    CookieLaw
  },

  computed: {
    isLoggedIn() {
      return this.$store.getters["UserAuth/isLoggedIn"];
    },
    isClubLoggedIn() {
      return this.$store.getters["ClubAuth/isClubLoggedIn"];
    },
    comp_NavigationDrawer: {
      get() {
        return this.$store.getters['GeneralApp/GET_NavbarDrawer'];
      },
      set(val) {
        this.$store.state.GeneralApp.NavigationDrawer = val;
      },
    },
    comp_ShowNavigation() {
      return this.$store.state.GeneralApp.Show_Navigation;
    },
  },

  data: () => ({
    drawer: false,
    showNavigations: true,
    links: [
      {name: 'Datenschutz', route: '/Datenschutz'},
      {name: 'about', route: '/about'},
    ],
  }),

  beforeMount() {
    this.getStartseiteText();
    this.getDSGVOText();
  },

  methods: {
    getDSGVOText() {
      this.$store.dispatch('GeneralApp/getDSGVOText');
    },
    getStartseiteText() {
      this.$store.dispatch('GeneralApp/getStartseiteText');
    },
    logout() {

      if (this.isLoggedIn) {
        this.$store.dispatch('UserAuth/logout')
            .then(() => this.$router.push('/'))
            .catch(err => console.log(err))
      } else if (this.isClubLoggedIn) {
        this.$store.dispatch('ClubAuth/logout')
            .then(() =>
                    this.$router.push('/'),
                this.$store.commit('GeneralApp/SET_SHOW_NAVIGATION', true),
            )
            .catch(err => console.log(err))
      }

    },


  },


}
</script>

<style scoped>

</style>
