const getters = {

    isClubLoggedIn: state => !!state.Clubtoken,
    authStatus: state => state.status,
    club: state => state.club,
    isClubToken: state => state.Clubtoken,
    isClubName: (state) => {
        if (state.club === null) {
            return '';
        }
        const ClubName = state.club.name;
        return ClubName;
    },
    isClubID: (state) => {
        if (state.club === null) {
            return '';
        }
        const ClubID = state.club.id;
        return ClubID;
    },
    GET_CLUBMENU: (state) => {

        return state.clubmenu;
    },
    can_RFID: (state) => {
        if (state.club === null) {
            return false
        }
        return state.club.can_rfid === 1
    }
};

export default getters;
