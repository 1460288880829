import {API} from "../../axios";
import swal from "sweetalert2";

const actions = {
    async getDSGVOText({commit}) {

        await API.post('/getText',
            {
                text_search: 'DSGVO'
            })
            .then((response) => {
                commit('get_DSGVOText', response.data.Text);
                // resolve(response)
            })
            .catch(error => {
                console.log(error);
            });
    },

    async getStartseiteText({commit}) {

        await API.post('/getText',
            {
                text_search: 'startseite'
            })
            .then((response) => {
                commit('get_StartseiteText', response.data.Text);
                // resolve(response)
            })
            .catch(error => {
                console.log(error);
            });
    },
    async search_users_clubs_Names({commit, rootState, rootGetters}, Data) {
        let token = '';
        if (rootGetters['UserAuth/isLoggedIn']) {
            token = `Bearer ${rootState.UserAuth.token}`
        } else if (rootGetters['ClubAuth/isClubLoggedIn']) {
            token = `Bearer ${rootState.ClubAuth.Clubtoken}`
        }
        API.defaults.headers.common['Authorization'] = token
        await API.get('/weapons/transfer/searchNames', {
            params: Data
        })
            .then((resp) => {
                commit('GET_SEARCH_NAMES_TRANSFER', resp.data);
                // swal.fire('Waffe löschen', resp.data, 'success');
            })
            .catch(error => {
                console.log(error);
                swal.fire('Name Suchen', 'Es ist etwas schiefgelaufen bei der Suche', 'warning');
            });
    },
    async weapon_transfer({dispatch, rootState, rootGetters}, Data) {
        let token = '';
        let url = '';
        let dispatchMethod = '';
        if (rootGetters['UserAuth/isLoggedIn']) {
            token = `Bearer ${rootState.UserAuth.token}`
            url = '/user';
            dispatchMethod = 'UserApp/getWeapons';
        } else if (rootGetters['ClubAuth/isClubLoggedIn']) {
            token = `Bearer ${rootState.ClubAuth.Clubtoken}`
            url = '/club';
            dispatchMethod = 'ClubApp/getWeapons';
        }
        API.defaults.headers.common['Authorization'] = token
        await API.post(url + '/weapons/transfer/Transfer_Weapon', Data)
            .then((resp) => {
                dispatch(dispatchMethod, null, {root: true})
                swal.fire('Waffen Überlassung', resp.data, 'success');
            })
            .catch(error => {
                swal.fire('Waffen Überlassung', error.response.data, 'warning');
            });
    },
    async new_Contact_us_Feeback({rootState, rootGetters}, Data) {
        let token = '';
        if (rootGetters['UserAuth/isLoggedIn']) {
            token = `Bearer ${rootState.UserAuth.token}`
        } else if (rootGetters['ClubAuth/isClubLoggedIn']) {
            token = `Bearer ${rootState.ClubAuth.Clubtoken}`
        }
        API.defaults.headers.common['Authorization'] = token
        await API.post('/contactus/newFeedback', Data)
            .then((resp) => {
                swal.fire('Feedback', resp.data, 'success');
            })
            .catch(error => {
                swal.fire('Feedback', error.response.data, 'warning');
            });
    },


};

export default actions;
