const state = {
    TextDSGVO:"",
    TextStartseite:"",
    NavigationDrawer: false,
    Show_Navigation: true,
    Weapon_Transfer_Search:[],

};

export default state;
