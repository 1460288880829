const mutations = {
    GET_BUND(state,data) {
        state.Bund = data
    },
    GET_VERBAND(state,data) {
        state.Verband = data
    },
    GET_DISZIPLINEN(state,data) {
        state.Disziplinen = data
    },
    GET_USERS(state,data) {
        state.Users = data
    },
    GET_CLUBS(state,data) {
        state.Clubs = data
    },
    GET_TEXTE(state, data) {
        state.Texte = data
    },
    GET_SINGLETEXTE(state, data) {
        state.SINGLETEXT = data
    },
    GET_FEEDBACK(state, data) {
        state.FEEDBACK = data
    },
    SET_ADMINDATA_CLEAR(state) {
        state.Bund = {},
        state.Verband = {},
        state.Disziplinen = {},
        state.Users = {},
        state.Clubs = {},
        state.Texte = {},
        state.SINGLETEXT = {}
    },
};

export default mutations;
