const state = {
    Bund: [],
    Verband: [],
    Disziplinen: [],
    Users: [],
    Clubs: [],
    Texte: [],
    SINGLETEXT: [],
    FEEDBACK: [],

};

export default state;
